var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"library library-row"},[_c('v-container',{staticClass:"pa-4",staticStyle:{"height":"100%","margin":"0","width":"100%","min-height":"95vh"},attrs:{"fluid":"","full":_vm.full}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[(_vm.category.name === 'all')?_c('h1',{staticStyle:{"margin-left":"12px !important"},style:({
					position: 'relative'
				})},[_vm._v(" Marketing Library ")]):_vm._e(),(_vm.category.name !== 'all')?_c('div',[_c('banner',[_c('img',{style:({
							height: '100%',
							width: '100%',
							objectFit: 'cover',
							zIndex: 1
						}),attrs:{"slot":"image","src":_vm.category.image_url,"alt":((_vm.category.name) + " category")},slot:"image"}),_c('div',{staticClass:"banner-back",attrs:{"slot":"back-button"},on:{"click":function($event){return _vm.setCategory({ name: 'all' })}},slot:"back-button"},[_c('i',{staticClass:"fas fa-chevron-left"}),_c('p',{staticClass:"text"},[_vm._v(" Back ")])]),_c('div',{staticClass:"banner-title",attrs:{"slot":"title"},slot:"title"},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.category.name))])]),_c('div',{staticClass:"banner-sub-title",attrs:{"slot":"sub-title"},slot:"sub-title"},[_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.category.banner_text)+" ")])]),_c('div',{staticClass:"banner-dl-button",attrs:{"slot":"download-button"},slot:"download-button"},[_c('Roundup-Button',{attrs:{"color":"primary","loading":_vm.marketingLoading,"disabled":_vm.marketingSize[_vm.category.name] === 0},on:{"click":function($event){return _vm.downloadZip(_vm.category.name)}}},[_vm._v(_vm._s(_vm.downloadButtonText))])],1)])],1):_vm._e()]),_c('div',{staticClass:"col",attrs:{"slot":"body"},slot:"body"},[(_vm.category.name === 'all')?_c('div',{staticClass:"library-row"},[_c('p',[_vm._v(" Find dozens of templates and examples to help you promote your RoundUp App program. ")])]):_vm._e(),(_vm.category.name === 'all')?_c('div',{staticClass:"library-row",staticStyle:{"margin-bottom":"0px"}},[_c('div',{staticClass:"tab",on:{"click":function($event){return _vm.setResource('donor')}}},[_c('p',{class:[_vm.resource === 'donor' ? 'selected' : '']},[_vm._v(" Donor Resources ")])]),_c('div',{staticClass:"tab",staticStyle:{"margin-left":"20px"},on:{"click":function($event){return _vm.setResource('support')}}},[_c('p',{class:[_vm.resource === 'support' ? 'selected' : '']},[_vm._v(" Support Resources ")])])]):_vm._e(),_c('div',{staticClass:"library-row",staticStyle:{"margin-top":"0px"}},_vm._l((_vm.marketingCategories),function(value,name){return _c('div',{key:name},[(
							_vm.resource === value.resource &&
								_vm.category.name === 'all'
						)?_c('div',[_c('div',{on:{"click":function($event){return _vm.setCategory(value)}}},[_c('file-tile',{attrs:{"type":"nav"}},[_c('img',{style:({
										height: '100%',
										width: '100%',
										objectFit: 'cover',
										borderRadius: '10px'
									}),attrs:{"slot":"image","src":value.image_url,"alt":((value.name) + " category")},slot:"image"}),_c('p',{staticClass:"nav-title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(value.name)+" ")])])],1)]):_vm._e()])}),0),(!_vm.marketingLoading && _vm.resource === 'donor')?_c('div',{staticStyle:{"width":"100%"}},_vm._l((_vm.marketingTemplates),function(value,name){return _c('div',{key:name,staticClass:"library-row",staticStyle:{"width":"100%"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCategory(value)),expression:"hasCategory(value)"}]},[_c('carousel',{attrs:{"id":_vm.formatTypeWithHyphens(name)}},[_c('p',{staticClass:"carousel-text",staticStyle:{"margin-bottom":"20px !important"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(name)+" ")]),_vm._l((_vm.filterTemplates(value)),function(slide){return _c('div',{key:slide.id,staticClass:"slide",attrs:{"slot":"slides"},slot:"slides"},[_c('div',{staticStyle:{"cursor":"pointer","padding-bottom":"20px","position":"relative"},on:{"click":function($event){return _vm.openModal(slide)}}},[_c('img',{class:[
											_vm.formatTypeWithHyphens(name),
											'slide-img'
										],attrs:{"src":slide.url,"alt":slide.type}}),(slide.canva_url)?_c('span',{staticClass:"canva-tag"},[_c('span',{staticClass:"canva-btn-i"})]):_vm._e()])])})],2)],1)])}),0):_vm._e(),(!_vm.marketingLoading && _vm.resource === 'support')?_c('div',{staticStyle:{"justify-content":"space-between","width":"100%"}},_vm._l((_vm.marketingFiles),function(value,name){return _c('div',{key:name,staticClass:"library-row",staticStyle:{"justify-content":"space-between","width":"100%","overflow-y":"hidden"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCategory(value)),expression:"hasCategory(value)"}]},[_c('carousel',[_c('p',{staticClass:"carousel-text",staticStyle:{"margin-bottom":"20px !important"},attrs:{"slot":"title"},slot:"title"},[_vm._v(" "+_vm._s(_vm.setCategoryName(name))+" ")]),_vm._l((_vm.filterTemplates(
									_vm.marketingFiles[name]
								)),function(slide){return _c('div',{key:slide.id,staticClass:"slide",attrs:{"slot":"slides"},slot:"slides"},[_c('pdf',{attrs:{"fileUrl":slide.url,"height":240,"width":240},on:{"click":function($event){return _vm.trackPDFDownload(slide)}}}),_c('p',{staticClass:"slide-title",staticStyle:{"width":"240px","margin-bottom":"20px !important","margin-top":"5px !important","font-weight":"500","font-size":"0.875rem"}},[_vm._v(" "+_vm._s(slide.title)+" ")])],1)})],2)],1)])}),0):_vm._e()]),_c('v-dialog',{attrs:{"max-width":"960px"},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('detail-view',{attrs:{"slide":_vm.modal.data,"institution":_vm.institution,"url":_vm.deeplink},on:{"close":function($event){_vm.modal.show = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }