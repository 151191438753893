<template>
	<a :href="fileUrl" target="_blank" @click="onClick">
		<pdf
			style="height: 310px; width: 240px "
			id="pdf-container"
			:src="fileUrl"
		>
		</pdf>
	</a>
</template>

<script>
import pdf from 'vue-pdf'

export default {
	name: 'PdfThumbnail',
	components: { pdf },
	props: {
		fileUrl: {
			type: String
		},
		width: {
			type: Number,
			default: 200
		},
		height: {
			type: Number,
			default: 200
		}
	},
	methods: {
		onClick() {
			if (window.analytics) {
				window.analytics.track('marketing_library_download', {
					url: this.fileUrl.replace(
						'https://admin-marketing-library.s3.amazonaws.com/',
						''
					),
					pdf: true
				})
			}
		}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';
#pdf-container {
	border: 1px solid $roundup-light-grey;
	border-radius: 10px;
	padding: 5px;
}
</style>
