<template>
	<section class="file">
		<div class="file-body" :class="[type]">
			<slot name="image"></slot>
		</div>
		<div class="file-footer">
			<slot name="title"></slot>
		</div>
	</section>
</template>

<script>
export default {
	name: 'roundup-file-tile',
	props: ['type'],
	computed: {}
}
</script>

<style lang="scss">
@import '../../styles/variables';

.file {
	margin: 20px 15px 20px 0px;
	cursor: pointer;
	position: relative;
	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
	}
}
.file-body {
	z-index: 0;
	&.nav {
		height: 90px;
		width: 180px;
	}
}
.file-footer {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 40%;
	width: 100%;
	z-index: 10;
	background-color: rgba(0, 0, 0, 0.4);
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
</style>
