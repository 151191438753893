<template>
	<div class="carousel">
		<slot name="title"></slot>
		<div class="carousel-body">
			<div class="carousel-controls-left" v-if="scrollLeft !== 0">
				<i class="fas fa-chevron-left fa-2x" @click="scroll(false)">
				</i>
			</div>
			<transition-group
				class="carousel-t-group"
				tag="div"
				@scroll.native="setScroll"
				ref="t-group"
			>
				<slot name="slides"></slot>
			</transition-group>
			<div
				class="carousel-controls-right"
				v-if="
					(this.offsetWidth < this.scrollWidth,
					!(this.offsetWidth + this.scrollLeft >= this.scrollWidth))
				"
			>
				<i class="fas fa-chevron-right fa-2x" @click="scroll(true)">
				</i>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'lib-carousel',
	props: ['id'],
	data() {
		return {
			offsetWidth: 0,
			scrollWidth: 0,
			scrollLeft: 0
		}
	},
	methods: {
		scroll(right) {
			// Get t-group ref
			let carousel = this.$refs['t-group'].$el

			// Determine if we're adding or subtracting units from scrollLeft
			let unitsToMove = 300
			unitsToMove = !right ? unitsToMove * -1 : unitsToMove

			var newScroll = unitsToMove + this.scrollLeft

			this.scrollLeft = newScroll
			carousel.scrollLeft = newScroll
		},

		setScroll(el) {
			const carousel = el.target
			this.scrollLeft = carousel.scrollLeft
		},
		async delay() {
			return new Promise(resolve => {
				setTimeout(() => {
					resolve()
				}, 500)
			})
		}
	},
	mounted() {
		if (this.$refs['t-group']) {
			let scroll = this.$refs['t-group'].$el
			this.offsetWidth = scroll.offsetWidth
			this.scrollWidth = scroll.scrollWidth
		}
	},
	updated() {
		this.$nextTick(async function() {
			if (this.$refs['t-group']) {
				let scroll = this.$refs['t-group'].$el
				await this.delay()
				this.offsetWidth = scroll.offsetWidth
				this.scrollWidth = scroll.scrollWidth
				this.scrollLeft = scroll.scrollLeft
			}
		})
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.carousel {
	display: flex;
	flex-direction: column;
	width: 100%;
	.carousel-text {
		margin-bottom: 20px;
		font-size: 20px;
	}
	.carousel-body {
		position: relative;
		max-width: 80vw;
		.carousel-controls-left {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%);
			.fa-chevron-left {
				&:before {
					transform: translateX(-2px);
				}
			}
		}
		.carousel-controls-right {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate(-50%, -50%);
			.fa-chevron-right {
				&:before {
					transform: translateX(2px);
				}
			}
		}
		.carousel-t-group {
			display: flex;
			justify-content: left;
			align-items: center;
			max-width: 90vw;
			overflow-x: auto;
			margin-right: 30px;
			flex: 1 1 auto;
		}
	}
}
.carousel .carousel-body .fa-chevron-left,
.carousel .carousel-body .fa-chevron-right {
	color: $roundup-medium-grey;
	background-color: #fff;
	font-size: 1.5em;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
	border-radius: 30px;
	height: 50px;
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.carousel .carousel-body .fa-chevron-left:active,
.carousel .carousel-body .fa-chevron-right:active {
	transform: translateY(2px);
}
</style>
