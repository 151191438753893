<template>
	<header class="lib-banner">
		<div class="body">
			<div class="overlay"></div>
			<div class="image">
				<slot name="image"></slot>
			</div>
			<div class="back-button">
				<slot name="back-button"></slot>
			</div>
			<div class="title">
				<slot name="title"></slot>
			</div>
			<div class="sub-title">
				<slot name="sub-title"></slot>
			</div>
			<div class="download-button">
				<slot name="download-button"></slot>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'lib-banner',
	props: [],
	data() {
		return {}
	}
}
</script>

<style lang="scss">
@import '../../../styles/variables';

.lib-banner {
	height: 297px;
	width: 100%;
	.body {
		position: relative;
		.overlay {
			background: rgba(0, 0, 0, 0.5);
			z-index: 1;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
		}
	}
	.image {
		height: 297px;
		width: 100%;
	}
	.back-button {
		top: 10%;
		left: 5%;
	}
	.title {
		top: 25%;
		left: 5%;
	}
	.sub-title {
		top: 50%;
		left: 5%;
	}
	.download-button {
		top: 60%;
		left: 5%;
	}
}
.lib-banner .back-button,
.lib-banner .title,
.lib-banner .sub-title,
.lib-banner .download-button {
	position: absolute;
	z-index: 10;
}
</style>
